import React from "react";

const PrivacyPolicy = () => (
  <div className="bg-white black mw8 pv5 ph3 w-100 center">
    <div className="f2 b mt4 mb3">Privacy Policy</div>
    <div className="mid-gray lh-copy mb3">
      This privacy policy governs the manner in which StreamKit collects, uses, maintains, and discloses information collected from users 
      (each, a "User") of the www.getstreamkits.com website ("Site") and the StreamKit mobile app ("App"), hereafter referred to in this 
      Privacy Policy as "StreamKit", "us", "our" or "we." For the purposes of this Agreement and our Terms of Service, any use of the terms 
      "StreamKit", "us", "our" or "we" includes Lofi Labs LLC, without limitation. This Privacy Policy is incorporated by reference in the 
      Lofi Labs Terms of Service, which govern your use of our Services.
    </div>
    <div className="mid-gray lh-copy mb3">
      The following statement discloses the guidelines Lofi Labs has established for protecting the information you provide to us during your 
      visit to the site and while using the app.
    </div>
    <div className="f4 b mb1 mt4">Twitch Account Information</div>
    <div className="mid-gray lh-copy mb3">
      When you sign in to the StreamKit app, we store basic account information provided to us by Twitch. You agree to provide us this information 
      through the Twitch authentication service. We do not store your Twitch password, nor do we ever have access to your password. Instead, we are granted 
      an authorization token from Twitch. We renew this authorization token at intervals as recommended by Twitch to ensure  a smooth login experience with your application.
    </div>
    <div className="mid-gray lh-copy mb3">
      You may remove StreamKit's access to this token at any time from your Twitch channel's settings. We are not responsible for any interruption of service this may lead to.
    </div>
    <div className="f4 b mb1 mt4">Channel Information</div>
    <div className="mid-gray lh-copy mb3">
      We collect information about your channel in order to operate our service. The data we collect is as follows:
    </div>
    <div className="b mb1">Channel Stats</div>
    <div className="mid-gray lh-copy mb3">
      We store data points about the number of viewers and followers your account has so we can display them as part of our services.
    </div>
    <div className="b mb1">Stream Stats</div>
    <div className="mid-gray lh-copy mb3">
      We store publicly available information about your live streams.
    </div>
    <div className="b mb1">Followers, Unfollowers, Viewers, and Follows</div>
    <div className="mid-gray lh-copy mb3">
      We store publicly available information about your followers, viewers, unfollowers, and users you follow. In addition we store dates relevant to your association with them 
      including the date they followed, viewed, unfollowed, or you followed them.
    </div>
    <div className="mb1 f4 mt4 b">Aggregated Statistics</div>
    <div className="mid-gray lh-copy mb3">
      We may collect statistics about the behavior of visitors to our website and users of the app. For example, we may aggregate anonymous telemetry data to determine popular features within our services.
    </div>
    <div className="mb1 f4 mt4 b">Website Visitors</div>
    <div className="mid-gray lh-copy mb3">
      Lofi Labs collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request.
    </div>
    <div className="mb1 f4 mt4 b">Email Communications</div>
    <div className="mid-gray lh-copy mb3">
      If you choose to stop receiving emails regarding StreamKit, please follow the unsubscribe instructions within each email communication or send an email to getstreamkit@gmail.com. We will not provide or share any 
      mailing lists or other information about you to another company or service for promotional purposes.

      Any service-related emails (help requests, feedback, etc.) generally do not offer an option to unsubscribe as they are necessary to provide the service you requested.
    </div>
    <div className="mb1 f4 mt4 b">Disclaimer</div>
    <div className="mb3 mid-gray lh-copy">
      While we continue to work hard to protect your data, no transmission over the Internet can be guaranteed to be absolutely secure, and we cannot ensure or warrant the security of any information you transmit to Lofi Labs. 
      Transmitting personal information is done at your own risk.
    </div>
    <div className="mb1 f4 mt4 b">Change to this privacy policy</div>
    <div className="mb3 mid-gray lh-copy">
      Lofi Labs has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about 
      how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of 
      modifications.
    </div>
    <div className="mb3 mid-gray lh-copy">
      By registering for your account, and by accessing or using our services, you consent to the collection, 
      transfer, manipulation, storage, disclosure and other uses of your information (collectively, "use of your information") as
      described in this privacy policy and our terms of service which may be accessed <a className="white" href="/terms">here</a>. If you do not or no longer agree with or consent to 
      this privacy policy and our terms of service you may not access or use our services.
    </div>
    <div className="mb1 f4 mt4 b">Questions?</div>
    <div className="mb3 mid-gray lh-copy">
      Send an email to getstreamkit@gmail.com with any questions related to the privacy policy or the terms of service.
    </div>
    <div className="mb3 mid-gray lh-copy">
      Last updated July 9th, 2021
    </div>
  </div>
)

export default PrivacyPolicy;
